import * as routes from "~/routes"
import * as times from "~/common/utils/times"
import classNames from "classnames"
import type React from "react"
import type { Component, Deployment } from "~/models/Component"
import { Chip } from "~/common/ui/Chip"
import { ComponentTime } from "./ComponentTime"
import { ComponentVersion } from "./ComponentVersion"
import { Link } from "~/common/ui/Link"
import { Option } from "effect"
import { Status } from "~/common/ui/Status"

type Props = React.PropsWithChildren<{
  component: Component
  id?: string
  isLoading: boolean
  isActive: boolean
  onClick?: (id: string) => void
}>

const failing = "FAILING"

const getUpdates = (data: Component) => {
  const availableDeployments = Option.getOrElse(
    data.AvailableDeployments,
    () => [],
  )

  const activeDeployment = data.ActiveDeployment

  return Option.match(activeDeployment, {
    onNone: () => [],
    onSome: (activeDeployment) => {
      return availableDeployments.filter((deployment: Deployment) => {
        const deploymentTS = Option.getOrNull(
          times.parseISO(deployment.CreatedTS),
        )

        const activeDeploymentTS = Option.getOrNull(
          times.parseISO(activeDeployment.CreatedTS),
        )

        if (deploymentTS == null || activeDeploymentTS == null) return false

        return times.isAfter(deploymentTS, activeDeploymentTS)
      })
    },
  })
}

const ChipUpdateAvailable = () => (
  <Chip className="bg-green-400">Update Available</Chip>
)

const ChipLocked = () => <Chip className="bg-amber-300">LOCKED</Chip>

const ComponentListItemContent = (props: Props) => {
  const { component, isLoading } = props
  const activeDeployment = Option.getOrNull(component.ActiveDeployment)
  const updates = getUpdates(component)

  if (isLoading) return <div>Loading...</div>

  return (
    <>
      {component.Name && <div className="font-semibold">{component.Name}</div>}

      {activeDeployment ? (
        <ComponentVersion type="GitHash" version={activeDeployment.Version} />
      ) : (
        <div>No active deployment</div>
      )}

      {activeDeployment && (
        <ComponentTime className="text-xs" time={activeDeployment.ModifiedTS} />
      )}

      <div className="pt-2">
        {updates.length > 0 && <ChipUpdateAvailable />}
        {!component.DeployPassing && <Status status={failing} />}
        {component.Locked && <ChipLocked />}
      </div>
    </>
  )
}

export const ComponentListItem = (props: Props) => {
  const { isActive, isLoading } = props
  const href = routes.generate(routes.routeComponent(props.component.Name))

  return (
    <Link
      className={classNames({
        "t-component-list-item": true,
        "border-b border-l-4 border-slate-200 block w-full px-3 pt-4 pb-2": true,
        "opacity-50": isLoading,
        "cursor-pointer": !isLoading,
        "bg-slate-100 border-l-sky-500": isActive,
        "border-l-sky-100": !isActive,
      })}
      href={href}
      disabled={isLoading}
    >
      <div className="text-left">
        <ComponentListItemContent {...props} />
      </div>
    </Link>
  )
}
