import { Schema } from "@effect/schema"
import { Option } from "effect"

export type DeploymentStatus = "SUCCESS" | "ERROR" | "PENDING" | "IDLE"

const CommentSchema = Schema.Struct({
  Comment: Schema.String,
  CreatedTS: Schema.String,
  Id: Schema.String,
  ModifiedTS: Schema.String,
})

export type Comment = typeof CommentSchema.Type

const DeploymentSchema = Schema.Struct({
  ChangeLog: Schema.String,
  CommitAuthor: Schema.String,
  ComponentName: Schema.String,
  CreatedTS: Schema.String,
  Id: Schema.String,
  ModifiedTS: Schema.String,
  PipelineSlug: Schema.String,
  Status: Schema.String,
  Version: Schema.String,
})

export type Deployment = typeof DeploymentSchema.Type

export const ComponentSchema = Schema.Struct({
  ActiveDeployment: Schema.OptionFromNullishOr(DeploymentSchema, undefined),
  AvailableDeployments: Schema.OptionFromNullishOr(
    Schema.Array(DeploymentSchema),
    undefined,
  ),
  AutoDeploy: Schema.Boolean,
  Comments: Schema.OptionFromNullishOr(Schema.Array(CommentSchema), undefined),
  DeploymentId: Schema.optionalWith(Schema.String, { nullable: true }),
  DeploymentOrder: Schema.Number,
  DeployPassing: Schema.Boolean,
  Id: Schema.String,
  Locked: Schema.Boolean,
  Name: Schema.String,
})

export type Component = typeof ComponentSchema.Type

export type ComponentInput = typeof ComponentSchema.Encoded

export const getAllDeployment = (
  component: Component,
): ReadonlyArray<Deployment> => {
  const availableDeployments = Option.getOrElse(
    component.AvailableDeployments,
    () => [],
  )

  return Option.match(component.ActiveDeployment, {
    onNone: () => availableDeployments,
    onSome: (activeDeployment) => [
      ...[activeDeployment],
      ...availableDeployments,
    ],
  })
}
