import * as times from "~/common/utils/times"
import { ComponentBuild } from "./ComponentBuild"
import { ComponentDeploySwitch } from "./ComponentDeploySwitch"
import { ComponentLockSwitch } from "./ComponentLockSwitch"
import { ComponentTime } from "./ComponentTime"
import type { Context } from "~/models/Context"
import { isDev } from "~/common/utils/stages"
import { Loading } from "~/common/ui/Loading"
import { Status } from "~/common/ui/Status"
import {
  type Component,
  type Comment,
  type DeploymentStatus,
  type Deployment,
  getAllDeployment,
} from "~/models/Component"
import { Option } from "effect"

const getCommentIsAfter = (prev: Comment, current: Comment): boolean => {
  const prevCommentCreated = times.parseISOOrNull(prev.CreatedTS)

  const currentCreated = times.parseISOOrNull(current.CreatedTS)

  if (prevCommentCreated == null) return false

  if (currentCreated == null) return false

  return times.isAfter(prevCommentCreated, currentCreated)
}

const getDeploymentIsAfter = (
  deployment: Deployment,
  activeDeployment: Deployment,
): boolean => {
  const deploymentCreated = times.parseISOOrNull(deployment.CreatedTS)

  const activeDeploymentCreated = times.parseISOOrNull(
    activeDeployment.CreatedTS,
  )

  if (deploymentCreated == null) return false

  if (activeDeploymentCreated == null) return false

  return times.isAfter(deploymentCreated, activeDeploymentCreated)
}

type Props = {
  component: Component
  context: Context
  deploymentStatuses: { [key: string]: DeploymentStatus }
  failing: Array<Component>
  isLoading: boolean
  isTogglingAutoDeploy: boolean
  onAutoDeployComponent: (component: Component, value: boolean) => void
  onDeployComponent: (deploymentId: string) => void
  onLockComponent: (
    component: Component,
    lock: boolean,
    comment: string,
  ) => void
}

export const ComponentItemContent = (props: Props) => {
  const { component, failing, isLoading } = props

  const activeDeployment = Option.getOrNull(component.ActiveDeployment)

  const deployments = getAllDeployment(component)

  const comments = Option.getOrElse(component.Comments, () => [])

  const blocking = failing.filter(
    (item) => item.DeploymentOrder < component.DeploymentOrder,
  )

  if (isLoading) return <Loading />

  return (
    <div className="t-component-item-content py-4">
      <div className="t-item-header border-b border-slate-300 pb-4 px-4">
        <div className="flex font-semibold">
          <div>{component.Name}</div>
          <div>{!component.DeployPassing && <Status status={"FAILING"} />}</div>
        </div>

        <div>
          {activeDeployment ? (
            <ComponentTime
              key={component.Id}
              time={activeDeployment.ModifiedTS}
            />
          ) : (
            <span>No active deployment</span>
          )}
          <div>
            {blocking && blocking.length > 0
              ? `Blocked by ${blocking[0]?.Name}`
              : undefined}
          </div>
        </div>

        <div className="t-actions py-2 space-x-4">
          <ComponentDeploySwitch
            key={component.Id}
            component={component}
            context={props.context}
            disabled={!isDev()}
            isBusy={props.isTogglingAutoDeploy}
            isChecked={component.AutoDeploy}
            onAutoDeployComponent={props.onAutoDeployComponent}
          />
          <ComponentLockSwitch
            key={component.Id}
            component={component}
            context={props.context}
            enabled={component.Locked}
            onLockComponent={props.onLockComponent}
          />
          <div className="flex">
            {comments && comments.length > 0
              ? `${
                  comments.reduce((prev, current) => {
                    if (prev) {
                      return getCommentIsAfter(prev, current) ? prev : current
                    }
                    return current
                  }).Comment
                }`
              : undefined}
          </div>
        </div>
      </div>

      <ul className="t-list">
        {component &&
          deployments.map((deployment) => {
            if (!deployment) return null

            const isAfter = activeDeployment
              ? getDeploymentIsAfter(deployment, activeDeployment)
              : false

            const isNew = activeDeployment && isAfter

            const isCurrent =
              activeDeployment && activeDeployment.Id === deployment.Id

            return (
              <li key={deployment.Id}>
                <ComponentBuild
                  key={component.Id}
                  activeVersion={activeDeployment?.Version}
                  context={props.context}
                  deployment={deployment}
                  deploymentStatuses={props.deploymentStatuses}
                  isCurrent={isCurrent || false}
                  isNew={isNew || false}
                  locked={component.Locked}
                  onDeployComponent={props.onDeployComponent}
                />
              </li>
            )
          })}
      </ul>
    </div>
  )
}
