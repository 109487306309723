import * as ptr from "path-to-regexp"
import { Data, Option } from "effect"

export type RouteDef<RouteT> =
  | {
      name: string
      path: string
      make: (params: Params) => RouteT
    }
  | {
      path: string
      children: Array<RouteDef<RouteT>>
    }

export type Params = ptr.ParamData

type Empty = Record<never, never>

type GenerateResult = Data.TaggedEnum<{
  GenerateError: { readonly error: string }
  GenerateNotFound: Empty
  GenerateOk: { readonly path: string }
}>

export const { GenerateError, GenerateNotFound, GenerateOk } =
  Data.taggedEnum<GenerateResult>()

export const resolver =
  <T>(routes: Array<RouteDef<T>>) =>
  (currentPath: string): Option.Option<T> => {
    return resolverDo(routes, currentPath, "")
  }

const resolverDo = <T>(
  routes: Array<RouteDef<T>>,
  currentPath: string,
  parentPath: string,
): Option.Option<T> => {
  const [first, ...rest] = routes

  if (first) {
    const pathToTest = parentPath + first.path
    const fn = ptr.match(pathToTest)
    const match = fn(currentPath)

    if (match && "make" in first) {
      const params = match.params
      return Option.some(first.make(params))
    }

    // Try children match

    if ("children" in first) {
      const childrenMatch = resolverDo(first.children, currentPath, pathToTest)
      if (Option.isSome(childrenMatch)) {
        return childrenMatch
      }
    }

    // Keep going with the rest
    return resolverDo(rest, currentPath, parentPath)
  }

  // Nothing matched
  return Option.none()
}

export const generator =
  <T>(routes: Array<RouteDef<T>>) =>
  (wantedName: string, params: Params = {}): GenerateResult => {
    return generatorDo({ routes, wantedName, params, parentPath: "" })
  }

const generatorDo = <T>(args: {
  routes: Array<RouteDef<T>>
  wantedName: string
  params: Params
  parentPath: string
}): GenerateResult => {
  const [first, ...rest] = args.routes

  if (first) {
    const pathToTest = args.parentPath + first.path

    // If current route has a name, test it
    if ("name" in first) {
      const testedRouteName = first.name
      if (args.wantedName === testedRouteName) {
        return compilePath(pathToTest, args.params)
      }
    }

    if ("children" in first) {
      const found = generatorDo({
        routes: first.children,
        wantedName: args.wantedName,
        params: args.params,
        parentPath: pathToTest,
      })

      if (found._tag !== "GenerateNotFound") {
        return found
      }
    }

    return generatorDo({
      routes: rest,
      wantedName: args.wantedName,
      params: args.params,
      parentPath: args.parentPath,
    })
  }

  return GenerateNotFound()
}

const compilePath = (path: string, params: Params): GenerateResult => {
  try {
    const compiled = ptr.compile(path)(params)
    const compiledWithSlash = compiled === "" ? "/" : compiled
    return GenerateOk({ path: compiledWithSlash })
  } catch (error: unknown) {
    if (error instanceof Error) {
      return GenerateError({ error: error.message })
    }

    return GenerateError({ error: "Unknown error" })
  }
}
