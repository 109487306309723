import * as navigation from "~/common/utils/navigation"
import * as organisationsApi from "~/common/api/organisationsApiV2"
import * as RemoteData from "~/models/RemoteData"
import * as routes from "~/routes"
import classNames from "classnames"
import invariant from "tiny-invariant"
import React from "react"
import type { Account } from "~/models/Account"
import { Array as EArray, Record as ERecord, Option, pipe } from "effect"
import { ButtonOutline } from "~/common/ui/Buttons"
import { Can, Role } from "~/common/ui/Can"
import { ClickToCopy } from "~/common/ui/ClickToCopy"
import type { Context } from "~/models/Context"
import type { Customer } from "~/models/Customer"
import { CUSTOMER_SUPPORT_PLANS } from "~/common/utils/constants"
import { FactoryButton } from "~/common/ui/FactoryButton"
import type { IdamConfig } from "~/models/IdamConfig"
import { LocalUserInviteSwitch } from "./CustomerAndOrgDetails/LocalUserInviteSwitch"
import type { Organisation } from "~/models/Organisation"
import { Panel } from "~/common/ui/Panels"
import { Select } from "~/common/ui/Forms"
import { useOrganisationAccounts } from "~/common/hooks/useOrganisationAccounts"

export const getCustomerSupportPlan = (
  customer: Customer,
): CUSTOMER_SUPPORT_PLANS => {
  const plan = Object.values(CUSTOMER_SUPPORT_PLANS).find(
    (x) => x === customer.SupportPlan,
  )
  return plan || CUSTOMER_SUPPORT_PLANS.STARTER
}

type JustChildren = React.PropsWithChildren<{ className?: string }>

const Label = (props: JustChildren) => (
  <div className="text-slate-500 pr-4">{props.children}</div>
)

const Value = (props: JustChildren) => (
  <div className="bg-slate-100 p-2 rounded">{props.children}</div>
)

const CellLabel = (props: JustChildren) => (
  <td
    className={classNames(
      "text-slate-500 align-top pr-6 py-2 border-b border-slate-200",
      props.className,
    )}
  >
    {props.children}
  </td>
)

const getHasExternalBillingRoot = (accounts: ReadonlyArray<Account>) => {
  return accounts.some((account) => {
    return !account.BillingAccountId && account.Origin === "EXTERNAL"
  })
}

const getNumberDiscoveredAccounts = (accounts: ReadonlyArray<Account>) => {
  return accounts.filter((account) => account.Status === "DISCOVERED").length
}

const getNumberActiveAccounts = (accounts: ReadonlyArray<Account>) => {
  return accounts.filter((account) => account.Status === "ACTIVE").length
}

const getAllowedDomains = (organisation: Organisation) => {
  return Option.getOrElse(organisation.AllowedDomains, () => "—")
}

const getEnableLocalUserInvite = (
  organisationIdamConfigs: ReadonlyArray<IdamConfig>,
): string => {
  // First we want the config for EnableLocalUserInvite
  return pipe(
    organisationIdamConfigs,
    EArray.findFirst((config) => config.key === "EnableLocalUserInvite"),
    Option.map((config) => config.value),
    Option.getOrElse(() => ""),
  )
}

const CellValue = (props: JustChildren) => (
  <td className="pb-1 border-b border-b-slate-200">{props.children}</td>
)

const PanelWithSpace = (props: JustChildren) => (
  <Panel className="p-4 mb-3">{props.children}</Panel>
)

type SelectOrgProps = React.PropsWithChildren<{
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: string
}>

const SelectOrg = (props: SelectOrgProps) => {
  return (
    <Select
      className="w-60"
      name="organization"
      onChange={props.onChange}
      value={props.value}
    >
      {props.children}
    </Select>
  )
}

type CustomerDetailsProps = {
  customer: Customer
}

const CustomerDetails = (props: CustomerDetailsProps) => {
  const { customer } = props

  return (
    <PanelWithSpace>
      <details>
        <summary
          aria-controls="panel-customer-details-content"
          id="panel-customer-details-header"
          className="font-semibold text-lg"
        >
          Customer Details
        </summary>
        <table className="mt-2">
          <tbody>
            <tr>
              <CellLabel>Customer Name</CellLabel>
              <CellValue>{customer.Name || "—"}</CellValue>
            </tr>
            <tr>
              <CellLabel>Company Name</CellLabel>
              <CellValue>{customer.CompanyName}</CellValue>
            </tr>
            <tr>
              <CellLabel>Customer ID</CellLabel>
              <CellValue>
                {customer.Id || "—"} <ClickToCopy text={customer.Id} />
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Customer Email</CellLabel>
              <CellValue>
                {customer.Email || "—"} <ClickToCopy text={customer.Email} />
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Status</CellLabel>
              <CellValue>
                <details>
                  <summary>{customer.Status || "—"}</summary>

                  <ul className="py-4 text-gray-600">
                    <li>
                      <strong>PROSPECT</strong> - Generally represents that
                      customer creation has begun but has run into a problem.
                    </li>
                    <li>
                      <strong>NEW</strong> - Not generally shown/seen.
                    </li>
                    <li>
                      <strong>INITIALIZING</strong> - Represents that the
                      customer is new and is currently in the process of being
                      created within the Stax Installation.
                    </li>
                    <li>
                      <strong>ACTIVE</strong> - Represents that the customer is
                      active in the system and is in use.
                    </li>
                    <li>
                      <strong>SUSPENDED</strong> - Represents that the customer
                      is still in the system and their resources may/may not be
                      still running, however access to Stax for this customer
                      has been suspended.
                    </li>
                    <li>
                      <strong>OFFBOARDED</strong> - Represents that the customer
                      has been removed/off boarded from the Stax installation
                      and their Stax account is no longer in use.
                    </li>
                  </ul>
                </details>
              </CellValue>
            </tr>

            <tr>
              <CellLabel>Support Plan</CellLabel>
              <CellValue>{getCustomerSupportPlan(customer)}</CellValue>
            </tr>
            <tr>
              <CellLabel>Type</CellLabel>
              <CellValue>
                <a
                  href="https://stax.atlassian.net/wiki/spaces/JUMA/pages/551387149/Stax+Customer+Types+Summary"
                  rel="noreferrer"
                  target="_blank"
                >
                  {customer.Type || "—"}
                </a>
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Internal Customer</CellLabel>
              <CellValue>
                {customer.Internal ? "Internal" : "External"}
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Factory Version</CellLabel>
              <CellValue>
                <details>
                  <summary>{customer.FactoryVersion}</summary>

                  <p className="py-4 text-gray-600">
                    This field has two purposes
                    <br />
                    1. Reflect what CustomerFactory version the customer has had
                    applied to it.
                    <br />
                    2. Allow a Stax Staff member to run the CustomerFactory for
                    a customer.
                  </p>

                  <p className="py-4 text-gray-600">
                    When we run a customer through the customer factory, a git
                    commit version reflecting the current CustomerFactory
                    component version is stored in the system. This represents
                    the version of the customer factory at the point of time
                    when the customer was run through it. It merely acts as a
                    descriptor to let Stax Staff know what version of customer
                    factory the customer was last run through.
                  </p>
                </details>
              </CellValue>
            </tr>
          </tbody>
        </table>
      </details>
    </PanelWithSpace>
  )
}

const PanelTitle = (props: JustChildren) => (
  <div className="text-lg pb-2">{props.children}</div>
)

const OrganizationTitle = () => <PanelTitle>Organisation</PanelTitle>

const runOrganisationalUnitSync = (wantedOrgId: string) => {
  const confirmation = window.prompt(
    `This action will sync the AWS Organizations OUs to the Stax DB.\n
    You should only be performing this action if you understand the impact.\n
    Are you sure you want to sync AWS OUs to Stax?\n
    If you are sure, type 'YES' below:\n`,
  )

  if (confirmation === "YES") {
    return organisationsApi.syncOrganisationalUnits(wantedOrgId)
  }

  return undefined
}

const runPolicySync = (wantedOrgId: string) => {
  const confirmation = window.prompt(
    `This action will sync the AWS Organizations policies to the Stax DB.\n
    You should only be performing this action if you understand the impact.\n
    Are you sure you want to sync AWS Policies to Stax?\n
    If you are sure, type 'YES' below:\n`,
  )

  if (confirmation === "YES") {
    return organisationsApi.syncPolicies(wantedOrgId)
  }

  return undefined
}

const ButtonApplyStaxPolicies = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Apply Stax/Guardrails Policies"
      disabled={props.disabled}
      onClick={() =>
        organisationsApi.applyStaxOrGuardrailsPolicies(props.orgId)
      }
      successMessage="Queued"
    />
  )
}

const ButtonRunAllAccountsSaga = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Run All Accounts Saga"
      disabled={props.disabled}
      onClick={() => organisationsApi.runAllAccountsSaga(props.orgId)}
      successMessage="Queued"
    />
  )
}

const ButtonRunOrgValidation = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Run Org Validation"
      disabled={props.disabled}
      onClick={() => organisationsApi.runOrgValidation(props.orgId)}
      successMessage="Queued"
    />
  )
}

const ButtonRunOrgSaga = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Run Org Saga"
      disabled={props.disabled}
      onClick={() => organisationsApi.invokeSaga(props.orgId)}
      successMessage="Queued"
    />
  )
}

const ButtonRunPolicySync = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Sync AWS Policies to Stax"
      disabled={props.disabled}
      onClick={() => runPolicySync(props.orgId)}
      successMessage="Queued"
    />
  )
}

const ButtonRunDiscovery = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Run discovery"
      disabled={props.disabled}
      onClick={() => organisationsApi.rediscoverAllAccounts(props.orgId)}
      successMessage="Queued"
    />
  )
}

const ButtonRunOrganisationalUnitSync = (props: {
  disabled: boolean
  orgId: string
}) => {
  return (
    <FactoryButton
      label="Sync AWS OUs to Stax"
      disabled={props.disabled}
      onClick={() => runOrganisationalUnitSync(props.orgId)}
      successMessage="Queued"
    />
  )
}

type Props = {
  context: Context
  customer: Customer
  organisation: Organisation
  organisationIdamConfigs: ReadonlyArray<IdamConfig>
  organisations: ReadonlyArray<Organisation>
  unusedBillingRootsCount: number
}

export const CustomerAndOrgDetails = (props: Props) => {
  const { customer, organisation, organisations, unusedBillingRootsCount } =
    props

  invariant(customer, "customer is required")
  invariant(organisation, "organisation is required")

  const orgId = organisation.Id
  const customerId = customer.Id

  const { remoteData: remoteDataAccounts } = useOrganisationAccounts(orgId)

  // Hooks, need to be declared before rendering anything

  const handleChangeOrg = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const orgId = event.currentTarget.value
      const nextRoute = routes.routeOrganisation(customerId, orgId)
      navigation.navigateToRoute(nextRoute)
    },
    [customerId],
  )

  const { Accounts: accounts } = RemoteData.getOrElse(
    remoteDataAccounts,
    () => ({
      Accounts: [],
    }),
  )

  const hasExternalBillingRoot = getHasExternalBillingRoot(accounts)

  const numDiscoveredAccounts = getNumberDiscoveredAccounts(accounts)

  const numActiveAccounts = getNumberActiveAccounts(accounts)

  const enableLocalUserInvite: string = getEnableLocalUserInvite(
    props.organisationIdamConfigs,
  )

  const features = Option.getOrElse(organisation.Features, () => ({}))

  return (
    <>
      <CustomerDetails customer={customer} />

      <PanelWithSpace>
        <OrganizationTitle />
        <div className="flex">
          <SelectOrg onChange={handleChangeOrg} value={orgId}>
            {organisations.map((org) => (
              <option value={org.Id} key={org.Id}>
                {org.Name}
              </option>
            ))}
          </SelectOrg>

          <Can
            currentUser={props.context.currentUser}
            requiredRole={Role.deployer}
            yes={
              <div className="pl-2">
                {customer.Email && (
                  <React.Fragment>
                    <ButtonOutline
                      title={
                        unusedBillingRootsCount === 0
                          ? "No available billing roots"
                          : "Create an organisation"
                      }
                      onClick={() => {
                        if (unusedBillingRootsCount > 0) {
                          const route =
                            routes.routeCustomerAddOrganisation(customerId)

                          navigation.navigateToRoute(route)
                        }
                      }}
                      disabled={unusedBillingRootsCount === 0}
                    >
                      + Add Organisation
                    </ButtonOutline>
                  </React.Fragment>
                )}
              </div>
            }
          />
        </div>
      </PanelWithSpace>

      <PanelWithSpace>
        <details>
          <summary className="font-semibold text-lg">
            Organisation Details ({organisation.Name})
          </summary>
          <div className="grid grid-cols-4">
            <div className="col-span-3 pr-6">
              <table>
                <tr>
                  <CellLabel>Name</CellLabel>
                  <CellValue>{organisation.Name}</CellValue>
                </tr>
                <tr>
                  <CellLabel>Alias</CellLabel>
                  <CellValue>{organisation.Alias}</CellValue>
                </tr>
                <tr>
                  <CellLabel>Org Id</CellLabel>
                  <CellValue>
                    {organisation.Id} <ClickToCopy text={organisation.Id} />
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>Billing Account ID</CellLabel>
                  <CellValue>
                    {organisation.BillingAccountId}{" "}
                    <ClickToCopy text={organisation.BillingAccountId} />
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>Salesforce ID</CellLabel>
                  <CellValue>
                    {organisation.SalesforceId || "—"}{" "}
                    <ClickToCopy text={organisation.SalesforceId} />
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>Spotlight ID</CellLabel>
                  <CellValue>
                    {organisation.ExternalStaxId}{" "}
                    <ClickToCopy text={organisation.ExternalStaxId} />
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>Status</CellLabel>
                  <CellValue>
                    <details>
                      <summary>{organisation.Status}</summary>

                      <ul className="py-4 w-2/3 text-gray-500 text-sm space-y-2">
                        <li>
                          <strong>NEW</strong>
                          <br />
                          Not generally shown/seen.
                        </li>
                        <li>
                          <strong>INITIALIZING</strong>
                          <br />
                          Represents that the organisation is new and is
                          currently in the process of being created within the
                          Stax Installation.
                        </li>
                        <li>
                          <strong>ACTIVE</strong>
                          <br />
                          Represents that the customers organisation is active
                          in the system and is in use.
                        </li>
                        <li>
                          <strong>SUSPENDED</strong>
                          <br />
                          Represents that the customer is still in the system
                          and their resources may/may not be still running,
                          however access to this organisation in Stax for this
                          customer has been suspended.
                        </li>
                      </ul>
                    </details>
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>Compliance Type</CellLabel>
                  <CellValue>
                    <span
                      style={
                        organisation.ComplianceType
                          ? {
                              color: "red",
                            }
                          : {}
                      }
                    >
                      {organisation.ComplianceType || "None"}
                    </span>
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>AWS Support Plan</CellLabel>
                  <CellValue>{organisation.AwsSupportType}</CellValue>
                </tr>
                <tr>
                  <CellLabel>AWS Partner Support</CellLabel>
                  <CellValue>
                    {organisation.AwsPartnerSupport ? "Yes" : "No"}
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>External Billing Root</CellLabel>
                  <CellValue>{hasExternalBillingRoot ? "Yes" : "No"}</CellValue>
                </tr>
                <tr>
                  <CellLabel>Factory Version</CellLabel>
                  <CellValue>
                    <details>
                      <summary>
                        {organisation.FactoryVersion}{" "}
                        <ClickToCopy text={organisation.FactoryVersion} />
                      </summary>
                      <p className="py-4 text-gray-600">
                        Represents what Organisation Factory version the
                        organisation is currently using.
                      </p>
                    </details>
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>Allowed domains</CellLabel>
                  <CellValue>{getAllowedDomains(organisation)}</CellValue>
                </tr>
                <tr>
                  <CellLabel>Compliance Type</CellLabel>
                  <CellValue>{organisation.ComplianceType || "—"}</CellValue>
                </tr>
                <tr>
                  <CellLabel>Marketplace Enabled?</CellLabel>
                  <CellValue>
                    {organisation.MarketplaceSignup ? "Yes" : "No"}
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel>AWS Partner Support?</CellLabel>
                  <CellValue>
                    {organisation.AwsPartnerSupport ? "Yes" : "No"}
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel className="text-nowrap">
                    AWS Email Template
                  </CellLabel>
                  <CellValue>
                    {organisation.AwsAccountEmailTemplate || "—"}{" "}
                    <ClickToCopy text={organisation.AwsAccountEmailTemplate} />
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel className="text-nowrap">
                    Local User Invite Enabled?
                  </CellLabel>

                  <CellValue>
                    <LocalUserInviteSwitch
                      context={props.context}
                      enabled={enableLocalUserInvite.toLowerCase() !== "false"}
                      orgId={organisation.Id}
                    />
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel className="align-top">Regions</CellLabel>
                  <CellValue>
                    {organisation.Regions.join(", ") || "—"}
                  </CellValue>
                </tr>
                <tr>
                  <CellLabel className="align-top">Features</CellLabel>
                  <CellValue>
                    <ul>
                      {ERecord.toEntries(features).map(([feature, value]) => (
                        <li key={feature} className="py-1">
                          <span>{feature}</span>
                          <span className="ml-2 text-slate-500 font-semibold">
                            {value.toString()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </CellValue>
                </tr>
              </table>
            </div>
            <div className="t-org-actions border-l border-l-slate-200 pl-4">
              <div className="py-4 space-y-2 border-b border-b-slate-200">
                <div>
                  <Label>Org Factory Version</Label>
                  <Value>{organisation.FactoryVersion}</Value>
                </div>

                <div title="Updates the Organization and runs Account Assurance over the Management, Security and Logging Accounts">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.deployer}
                    yes={<ButtonRunOrgSaga disabled={false} orgId={orgId} />}
                    no={<ButtonRunOrgSaga disabled={true} orgId={orgId} />}
                  />
                </div>
                <div title="Run Org Validation">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.deployer}
                    yes={
                      <ButtonRunOrgValidation disabled={false} orgId={orgId} />
                    }
                    no={
                      <ButtonRunOrgValidation disabled={true} orgId={orgId} />
                    }
                  />
                </div>
              </div>

              <div className="pb-4 space-y-2 border-b border-b-slate-200">
                <div>
                  <Label>Stax Accounts</Label>
                  <Value>{`${numActiveAccounts} active accounts`}</Value>
                </div>

                <div title="Runs Account Assurance over all active Accounts (except for the Management, Security and Logging Accounts). If you are running this for multiple Orgs, it is best to only run 5 Orgs at a time.">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.deployer}
                    yes={
                      <ButtonRunAllAccountsSaga
                        disabled={false}
                        orgId={orgId}
                      />
                    }
                    no={
                      <ButtonRunAllAccountsSaga disabled={true} orgId={orgId} />
                    }
                  />
                </div>
              </div>

              <div className="py-4 space-y-2 border-b border-b-slate-200">
                <div>
                  <Label>Stax account discovery</Label>
                  <Value>{`${numDiscoveredAccounts} discovered accounts`}</Value>
                </div>

                <div title="Checks if there are any Accounts within the Organization that have not been onboarded to Stax and presents them to the customer as 'Discovered' within the Stax Console">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.customersupport}
                    yes={<ButtonRunDiscovery disabled={false} orgId={orgId} />}
                    no={<ButtonRunDiscovery disabled={true} orgId={orgId} />}
                  />
                </div>
              </div>

              <div className="py-4 space-y-2 border-b border-b-slate-200">
                <div>
                  <Label>Stax Policies</Label>
                </div>

                <div title="Applies Guardrails Policies to the Customer's AWS Organization. Stax policies are applied instead if the Customer does not have Configurable Guardrails enabled.">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.admin}
                    yes={
                      <ButtonApplyStaxPolicies disabled={false} orgId={orgId} />
                    }
                    no={
                      <ButtonApplyStaxPolicies disabled={true} orgId={orgId} />
                    }
                  />
                </div>

                <div title="Syncs the customers policies from AWS to Stax">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.admin}
                    yes={<ButtonRunPolicySync disabled={false} orgId={orgId} />}
                    no={<ButtonRunPolicySync disabled={true} orgId={orgId} />}
                  />
                </div>
              </div>

              <div className="py-4 space-y-2 border-b border-b-slate-200">
                <div>
                  <Label>Stax Organisational Units</Label>
                </div>

                <div title="Syncs the customers OUs from AWS to Stax">
                  <Can
                    currentUser={props.context.currentUser}
                    requiredRole={Role.admin}
                    yes={
                      <ButtonRunOrganisationalUnitSync
                        disabled={false}
                        orgId={orgId}
                      />
                    }
                    no={
                      <ButtonRunOrganisationalUnitSync
                        disabled={true}
                        orgId={orgId}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </details>
      </PanelWithSpace>
    </>
  )
}
