export const validateAwsIdV2 = (id: string): boolean | string => {
  const onlyNumbersRegex = /^[0-9]+$/
  if (id.length === 12 && onlyNumbersRegex.test(id)) {
    return true
  }

  return "Must be of exact length 12 and consist of only digits"
}

export const validateEmailV2 = (email: string): boolean | string => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  if (!regex.test(email)) {
    return "Must be a valid email address"
  }

  return true
}
