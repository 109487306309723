export enum AWS_SUPPORT_TYPES {
  BASIC = "BASIC",
  DEVELOPER = "DEVELOPER",
  BUSINESS = "BUSINESS",
  ENTERPRISE = "ENTERPRISE",
}

export enum CUSTOMER_SUPPORT_PLANS {
  STARTER = "STARTER",
  PROFESSIONAL = "PROFESSIONAL",
  ENTERPRISE = "BUSINESS CRITICAL",
}

export enum CUSTOMER_TYPES {
  DIRECT = "DIRECT",
  ECAM = "ECAM",
}
