import * as Form from "~/common/ui/Form"
import * as middleware from "~/common/api/middleware"
import * as provisionApi from "~/common/api/provisionApiV2"
import { ButtonOutline } from "~/common/ui/Buttons"
import { FormTextInputV2 } from "~/common/ui/Forms"
import { Fragment } from "react"
import { Notice } from "~/common/ui/Notices"
import { Schema } from "@effect/schema"
import {
  useElmish,
  type InitResult,
  type UpdateReturnType,
  type MsgSource,
} from "react-elmish"

type MessageSource = MsgSource<"ProvisionVerification">

type LocalMessage = { name: "NoOp" } & MessageSource

const ValidFormDataSchema = Schema.Struct({
  awsAccountId: Schema.NonEmptyString,
  customerName: Schema.NonEmptyString,
  awsAccountEmail: Schema.NonEmptyString,
})

type ValidFormData = typeof ValidFormDataSchema.Type

type InputFormData = typeof ValidFormDataSchema.Encoded

type FieldNames = keyof ValidFormData

const ResponseDataSchema = Schema.Struct({
  result: Schema.String,
})

type ResponseData = typeof ResponseDataSchema.Type

type Message = LocalMessage | Form.Message<FieldNames, ResponseData>

type Model = Form.Model<FieldNames, ResponseData>

type InitProps = Form.InitProps<FieldNames, ValidFormData, ResponseData>

const init = (props: InitProps): InitResult<Model, Message> => {
  return Form.init(props)
}

const update = (
  model: Model,
  msg: Message,
  props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.source) {
    case "ProvisionVerification": {
      return [model]
    }
    case "Form": {
      return Form.update(model, msg, props)
    }
  }
}

const onSubmit = async (
  values: ValidFormData,
): Promise<Form.SubmitResponse<ResponseData>> => {
  const payload = {
    AwsAccountId: values.awsAccountId,
    // Explictly null for the API
    CustomerName: values.customerName ?? null,
    AwsAccountEmail: values.awsAccountEmail ?? null,
  }

  const promise = provisionApi.verifyOnboarding(payload)

  return middleware.amplifyJsonResponseToRemoteData(ResponseDataSchema)(promise)
}

const initialValues: InputFormData = {
  awsAccountId: "",
  customerName: "",
  awsAccountEmail: "",
}

export const ProvisionVerification = () => {
  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: {
      initialValues,
      onSubmit,
      validDataSchema: ValidFormDataSchema,
    },
    init,
    update,
    name: "ProvisionVerification",
  })

  const awsAccountIdField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "awsAccountId",
  })

  const customerNameField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "customerName",
  })

  const accountEmailField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "awsAccountEmail",
  })

  return (
    <Fragment>
      {model.submitResponse._tag === "Success" ? (
        <Notice className="my-3" variant="Success">
          <p>
            {model.submitResponse.data.result}. Results are relayed to the Stax
            Slack channel{" "}
            <a
              href="slack://channel?team=T27H23T2M&id=C034SFF4YER"
              target="__blank"
              className="bold"
            >
              #onboarding-findings
            </a>
          </p>
        </Notice>
      ) : (
        <Notice className="my-3">
          <p>
            This initiates a background process where the results are relayed to
            the Stax Slack channel{" "}
            <a
              href="slack://channel?team=T27H23T2M&id=C034SFF4YER"
              target="__blank"
              className="bold"
            >
              #onboarding-findings
            </a>
          </p>
        </Notice>
      )}
      <Form.Form dispatch={dispatch} model={model}>
        <FormTextInputV2
          {...awsAccountIdField}
          label="Account ID"
          required={false}
        />

        <FormTextInputV2
          {...customerNameField}
          label="Customer Name"
          required={false}
        />

        <FormTextInputV2
          {...accountEmailField}
          helpText="Organisation management account email"
          label="Account Email"
          required={false}
        />

        <div className="flex justify-end">
          <ButtonOutline type="submit">Verify</ButtonOutline>
        </div>
      </Form.Form>
    </Fragment>
  )
}
