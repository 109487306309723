import * as RemoteData from "~/models/RemoteData"
import type React from "react"
import { Array as EArray, Either, ParseResult, Option, pipe } from "effect"
import { Schema } from "@effect/schema"
import { Toast } from "~/common/ui/Toast"
import {
  cmd,
  type Dispatch,
  type InitResult,
  type MsgSource,
  type UpdateReturnType,
} from "react-elmish"

// We use a MsgSource to differentiate between the messages
type MessageSource = MsgSource<"Form">

export type SubmitResponse<Data> = RemoteData.RemoteData<Data, string>

// Add that MessageSource to all the messages
export type Message<Fields extends string, ResponseData> =
  | ({
      name: "BlurredField"
      fieldName: Fields
    } & MessageSource)
  | ({
      name: "ChangedField"
      fieldName: Fields
      value: string
    } & MessageSource)
  | ({ name: "SubmittedForm" } & MessageSource)
  | ({
      name: "SubmittedFormDone"
      data: SubmitResponse<ResponseData>
    } & MessageSource)
  | ({ name: "SubmittedFormFailed" } & MessageSource)

type FormSchema<Fields extends string, ValidData> = Schema.Schema<
  ValidData,
  Record<Fields, string>
>

type FieldError<Fields extends string> = {
  fieldName: Fields
  message: string
}

type FieldErrors<Fields extends string> = Array<FieldError<Fields>>

type GenericFormData<Fields extends string> = Record<Fields, string>

export type Model<Fields extends string, ResponseData> = {
  formData: GenericFormData<Fields>
  errors: FieldErrors<Fields>
  submitResponse: SubmitResponse<ResponseData>
  submitted: boolean
  touched: Set<Fields>
}

export const newModel = <Fields extends string, ResponseData>(
  initialValues: Record<Fields, string>,
): Model<Fields, ResponseData> => ({
  formData: initialValues,
  errors: [],
  submitResponse: RemoteData.NotAsked(),
  submitted: false,
  touched: new Set(),
})

export type InitProps<Fields extends string, ValidData, ResponseData> = {
  initialValues: Record<Fields, string>
  onSubmit: (data: ValidData) => Promise<SubmitResponse<ResponseData>>
  validDataSchema: FormSchema<Fields, ValidData>
}

export const init = <Fields extends string, ValidData, ResponseData>(
  props: InitProps<Fields, ValidData, ResponseData>,
): InitResult<Model<Fields, ResponseData>, Message<Fields, ResponseData>> => {
  const model = newModel<Fields, ResponseData>(props.initialValues)
  return [model]
}

export const validate =
  <Fields extends string, ValidData>(schema: FormSchema<Fields, ValidData>) =>
  (
    form: GenericFormData<Fields>,
  ): Either.Either<ValidData, ParseResult.ParseError> => {
    // @ts-ignore
    return Schema.decodeUnknownEither(schema)(form, { errors: "all" })
  }

const validateAndTransformErrors =
  <Fields extends string, ValidData>(schema: FormSchema<Fields, ValidData>) =>
  (
    form: GenericFormData<Fields>,
  ): Either.Either<ValidData, FieldErrors<Fields>> =>
    pipe(form, validate(schema), Either.mapLeft(transformParseErrors<Fields>))

const transformParseErrors = <Fields extends string>(
  parseError: ParseResult.ParseError,
): FieldErrors<Fields> =>
  pipe(
    ParseResult.ArrayFormatter.formatErrorSync(parseError),
    EArray.map((error: ParseResult.ArrayFormatterIssue) => ({
      fieldName: error.path.join("") as Fields,
      message: error.message,
    })),
  )

export const update = <Fields extends string, ValidData, ResponseData>(
  model: Model<Fields, ResponseData>,
  msg: Message<Fields, ResponseData>,
  props: InitProps<Fields, ValidData, ResponseData>,
): UpdateReturnType<
  Model<Fields, ResponseData>,
  Message<Fields, ResponseData>
> => {
  switch (msg.name) {
    case "BlurredField": {
      const result = validateAndTransformErrors<Fields, ValidData>(
        props.validDataSchema,
      )(model.formData)

      const errors = Either.match(result, {
        onLeft: (errors) => errors,
        onRight: () => [],
      })

      const touched = model.touched.add(msg.fieldName)

      return [{ ...model, errors, touched }]
    }

    case "ChangedField": {
      model.formData[msg.fieldName] = msg.value
      return [model]
    }

    case "SubmittedForm": {
      const result = validateAndTransformErrors<Fields, ValidData>(
        props.validDataSchema,
      )(model.formData)

      return Either.match(result, {
        onLeft: (errors) => {
          return [{ ...model, errors, submitted: true }]
        },
        onRight: (validForm: ValidData) => {
          const onSuccess = (
            data: SubmitResponse<ResponseData>,
          ): Message<Fields, ResponseData> => {
            return {
              data,
              name: "SubmittedFormDone",
              source: "Form",
            }
          }

          const onFailure = (): Message<Fields, ResponseData> => {
            return {
              name: "SubmittedFormFailed",
              source: "Form",
            }
          }

          return [
            { ...model, submitResponse: RemoteData.Loading(), submitted: true },
            cmd.ofEither(() => props.onSubmit(validForm), onSuccess, onFailure),
          ]
        },
      })
    }

    case "SubmittedFormDone": {
      return [{ ...model, submitResponse: msg.data }]
    }

    case "SubmittedFormFailed": {
      return [{ ...model, submitResponse: RemoteData.NotAsked() }]
    }
  }
}

const getFieldValue = <Fields extends string, ResponseData>(
  model: Model<Fields, ResponseData>,
  fieldName: Fields,
): string => {
  return model.formData[fieldName] ?? ""
}

const getFieldTouched = <Fields extends string>(args: {
  touched: Set<Fields>
  fieldName: Fields
}): boolean => {
  return args.touched.has(args.fieldName)
}

const getFieldShouldDisplayError = <Fields extends string>(args: {
  touched: Set<Fields>
  submitted: boolean
  fieldName: Fields
}): boolean => {
  return (
    getFieldTouched({ touched: args.touched, fieldName: args.fieldName }) ||
    args.submitted
  )
}

const getFieldError = <Fields extends string>(args: {
  errors: FieldErrors<Fields>
  fieldName: Fields
}): string | undefined => {
  return pipe(
    args.errors,
    EArray.findFirst((error) => error.fieldName === args.fieldName),
    Option.map((error) => error.message),
    Option.getOrUndefined,
  )
}

export const getFieldErrorToDisplay = <Fields extends string>(args: {
  errors: FieldErrors<Fields>
  touched: Set<Fields>
  submitted: boolean
  fieldName: Fields
}): string | undefined => {
  const shouldDisplay = getFieldShouldDisplayError({
    fieldName: args.fieldName,
    submitted: args.submitted,
    touched: args.touched,
  })
  return shouldDisplay
    ? getFieldError({ errors: args.errors, fieldName: args.fieldName })
    : undefined
}

const onFormSubmit =
  <Fields extends string, ResponseData>(
    dispatch: Dispatch<Message<Fields, ResponseData>>,
  ) =>
  (event: React.FormEvent<HTMLFormElement>) => {
    const message: Message<Fields, ResponseData> = {
      name: "SubmittedForm",
      source: "Form",
    }
    dispatch(message)
    event.preventDefault()
  }

export const formFieldAttributes = <Fields extends string, ResponseData>(args: {
  dispatch: Dispatch<Message<Fields, ResponseData>>
  model: Model<Fields, ResponseData>
  fieldName: Fields
}) => {
  const { fieldName, model, dispatch } = args

  const error = getFieldErrorToDisplay({
    errors: model.errors,
    fieldName,
    submitted: model.submitted,
    touched: model.touched,
  })

  return {
    disabled: model.submitResponse._tag === "Loading",
    error,
    id: fieldName,
    name: fieldName,
    onBlur: () => {
      const message: Message<Fields, ResponseData> = {
        name: "BlurredField",
        fieldName,
        source: "Form",
      }
      dispatch(message)
    },
    onChange: (value: string) => {
      const message: Message<Fields, ResponseData> = {
        fieldName,
        name: "ChangedField",
        source: "Form",
        value,
      }
      dispatch(message)
    },
    value: getFieldValue(model, fieldName),
  }
}

export const submitButtonAttributes = <Fields extends string, ResponseData>(
  model: Model<Fields, ResponseData>,
) => {
  return { disabled: model.submitResponse._tag === "Loading" }
}

const MaybeToast = <ResponseData,>(props: {
  submitResponse: SubmitResponse<ResponseData>
}) => {
  const { submitResponse } = props

  if (submitResponse._tag === "Success") {
    return <Toast message="Done" variant="success" />
  }

  if (submitResponse._tag === "Failure") {
    const error = submitResponse.error
    return <Toast message={error} variant="error" />
  }

  return null
}

type FormProps<Fields extends string, ResponseData> = React.PropsWithChildren<{
  dispatch: Dispatch<Message<Fields, ResponseData>>
  model: Model<string, ResponseData>
}>

export const Form = <Fields extends string, ResponseData>(
  props: FormProps<Fields, ResponseData>,
) => {
  return (
    <form onSubmit={onFormSubmit(props.dispatch)}>
      <MaybeToast submitResponse={props.model.submitResponse} />
      {props.children}
    </form>
  )
}
