import { CUSTOMER_SUPPORT_PLANS } from "~/common/utils/constants"
import { Schema } from "@effect/schema"

const CustomerSchema = Schema.Struct({
  CognitoUserId: Schema.String,
  CompanyName: Schema.String,
  CreatedBy: Schema.String,
  CreatedTS: Schema.String,
  Email: Schema.String,
  FactoryVersion: Schema.String,
  Id: Schema.String,
  Internal: Schema.Boolean,
  ModifiedTS: Schema.String,
  Name: Schema.String,
  OrgsOuId: Schema.String,
  Status: Schema.String,
  SupportPlan: Schema.String,
  Type: Schema.String,
})

export type Customer = typeof CustomerSchema.Type

export const fixture = (): Customer => ({
  CognitoUserId: "",
  CompanyName: "",
  CreatedBy: "",
  CreatedTS: "",
  Email: "",
  FactoryVersion: "",
  Id: "123",
  Internal: false,
  ModifiedTS: "",
  Name: "Name",
  OrgsOuId: "",
  Status: "",
  SupportPlan: CUSTOMER_SUPPORT_PLANS.STARTER,
  Type: "",
})

export const CustomersResponseSchema = Schema.Struct({
  Customers: Schema.Array(CustomerSchema),
})
