import * as log from "~/common/utils/log"
import * as RemoteData from "~/models/RemoteData"
import type { DocumentType } from "@aws-amplify/core/internals/utils"
import { ApiError } from "aws-amplify/api"
import { Either } from "effect"
import { Schema } from "@effect/schema"
import { type AmplifyResponse, isSuccessCode } from "~/common/api/apiFetchers"

const ErrorBodySchema = Schema.Struct({ Error: Schema.String })

/*
Take a response from amplify
Return a RemoteData wrapping the raw response
This doesn't parse the body
*/
export const amplifyResponseToRemoteData = async (
  promise: Promise<AmplifyResponse>,
): Promise<RemoteData.RemoteData<AmplifyResponse, unknown>> => {
  return promise
    .then((amplifyResponse) => {
      if (isSuccessCode(amplifyResponse)) {
        return RemoteData.Success({ data: amplifyResponse })
      }
      return RemoteData.Failure({ error: "Something went wrong" })
    })
    .catch((error: Error) => {
      log.error(error)
      return RemoteData.Failure({ error: "Something went wrong" })
    })
}

/*
Take a response from amplify, which must have a JSON body
Parse the JSON body and return a RemoteData
*/
export const amplifyJsonResponseToRemoteData =
  <Data>(schema: Schema.Schema<Data>) =>
  async (
    promise: Promise<AmplifyResponse>,
  ): Promise<RemoteData.RemoteData<Data, string>> => {
    return promise
      .then((apiResponse) =>
        amplifyJsonResponseSuccessToRemoteData(apiResponse, schema),
      )
      .catch((error: Error) => {
        log.error(error)
        if (error instanceof ApiError) {
          if (error.response?.body) {
            const { body } = error.response
            return RemoteData.Failure({ error: body })
          }
        }
        return RemoteData.Failure({ error: "Something went wrong" })
      })
  }

const amplifyJsonResponseSuccessToRemoteData = async <Data>(
  response: AmplifyResponse,
  schema: Schema.Schema<Data>,
): Promise<RemoteData.RemoteData<Data, string>> => {
  const json = await response.body.json()

  if (isSuccessCode(response)) {
    return maybeSuccessBody(json, schema)
  }

  return maybeErrorBody<Data>(json)
}

const maybeSuccessBody = <Data>(
  json: DocumentType,
  schema: Schema.Schema<Data>,
): RemoteData.RemoteData<Data, string> => {
  const result = Schema.decodeUnknownEither(schema)(json)

  return Either.match(result, {
    onLeft: (parseError) => {
      log.error(parseError)

      return RemoteData.Failure({
        error: "Failed to parse response",
      })
    },
    onRight: (data) => {
      return RemoteData.Success({ data })
    },
  })
}

const maybeErrorBody = <Data>(
  json: DocumentType,
): RemoteData.RemoteData<Data, string> => {
  // Try to parse the body as error
  const errorBodyResult = Schema.decodeUnknownEither(ErrorBodySchema)(json)

  return Either.match(errorBodyResult, {
    onLeft: (parseError) => {
      log.error(parseError)

      return RemoteData.Failure({
        error: "Failed to parse error body",
      })
    },
    onRight: (errorBody) =>
      RemoteData.Failure({
        error: errorBody.Error,
      }),
  })
}
