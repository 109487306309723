import * as log from "~/common/utils/log"
import { Fragment } from "react"
import { DateText } from "~/common/ui/DateText"
import { Loading } from "~/common/ui/Loading"
import { NoResults } from "~/common/ui/NoResults"
import type { Organisation } from "~/models/Organisation"
import type { Policy } from "~/models/Policy"
import type { PolicyAttachment } from "~/models/PolicyAttachment"
import { Status } from "~/common/ui/Status"
import {
  useOrganisationPolicies,
  useOrganisationPolicieAttachements,
} from "~/common/hooks/useOrganisationPolicies"
import { Option } from "effect"

const headers = [
  { title: "Name" },
  { title: "Description" },
  { title: "Organisation Attached" },
  { title: "Policy Type" },
  { title: "Policy Owner" },
  { title: "Status" },
  { title: "Modified" },
]

type Props = {
  organisation: Organisation
}

const getPolicyAttachmentTarget = (attachement: PolicyAttachment): string => {
  const organisationalUnitId = Option.getOrNull(
    attachement.OrganisationalUnitId,
  )

  const accountId = Option.getOrNull(attachement.AccountId)

  return organisationalUnitId || accountId || ""
}

const OrganisationAttachment = ({ policy }: { policy: Policy }) => {
  const value = policy.OrganisationAttachment ?? {}
  const keys = Object.keys(value)

  if (keys.length === 0) {
    return <>FALSE</>
  }

  return <>TRUE</>
}

const PolicyAttachments = ({
  policyAttachments,
}: {
  policyAttachments: ReadonlyArray<PolicyAttachment>
}) => {
  return (
    <table className="mt-3 space-y-2">
      <thead>
        <th>Type</th>
        <th>Target</th>
        <th>Status</th>
      </thead>
      <tbody>
        {policyAttachments.map((attachment) => (
          <tr key={attachment.Id}>
            <td>{attachment.PolicyAttachmentType}</td>
            <td>{getPolicyAttachmentTarget(attachment)}</td>
            <td>{attachment.Status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const CustomerPolicyList = (props: Props) => {
  const { organisation } = props
  const organisationId = organisation.Id

  const { remoteData } = useOrganisationPolicies(organisationId)

  const { remoteData: remoteDataAttachments } =
    useOrganisationPolicieAttachements(organisationId)

  if (organisation.Status === "SUSPENDED") {
    return <NoResults title="Organisation is suspended" />
  }

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  const isLoadingAttachements =
    remoteDataAttachments._tag === "Loading" ||
    remoteDataAttachments._tag === "NotAsked"

  if (isLoading || isLoadingAttachements) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Policies" />
  }

  if (remoteDataAttachments._tag === "Failure") {
    log.error(remoteDataAttachments.error)
    return <NoResults title="Error fetching Policy Attachments" />
  }

  const { Policies: policies } = remoteData.data
  const { PolicyAttachments: policyAttachments } = remoteDataAttachments.data

  if (policies.length === 0) {
    return <NoResults title="No policies found" />
  }

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {policies.map((policy) => {
          const relevantAttachments = policyAttachments.filter(
            (attachment) => attachment.PolicyId === policy.Id,
          )

          return (
            <Fragment key={policy.Id}>
              <tr key={policy.Id}>
                <td colSpan={7} className="border-t-4 border-t-slate-200">
                  <span className="font-semibold text-slate-500">Id:</span>{" "}
                  {policy.Id} ({policy.AwsId})
                </td>
              </tr>
              <tr key={policy.Id}>
                <td>{policy.Name}</td>
                <td style={{ maxWidth: "20rem" }}>{policy.Description}</td>
                <td>
                  <OrganisationAttachment policy={policy} />
                </td>
                <td>{policy.PolicyType}</td>
                <td>{policy.PolicyOwner}</td>
                <td>
                  <Status status={policy.Status} />
                </td>
                <td>
                  <DateText dateString={policy.ModifiedTS} />
                </td>
              </tr>
              <tr key={`${policy.Id}-attachments`}>
                <td colSpan={7}>
                  {relevantAttachments.length ? (
                    <details>
                      <summary className="font-semibold text-slate-500">
                        Attachments ({relevantAttachments.length}):
                      </summary>

                      <PolicyAttachments
                        policyAttachments={relevantAttachments}
                      />
                    </details>
                  ) : (
                    <p>No Attachments</p>
                  )}
                </td>
              </tr>
              <tr key={`${policy.Id}-policy`}>
                <td colSpan={7}>
                  <details>
                    <summary className="font-semibold text-slate-500">
                      Policy:
                    </summary>
                    <pre className="w-full mt-2 p-3 border border-slate-300 rounded bg-slate-100">
                      {JSON.stringify(policy.Content, null, 2)}
                    </pre>
                  </details>
                </td>
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}
