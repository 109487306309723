import type { Context } from "~/models/Context"
import { NarrowContainerWithPadding } from "~/common/ui/Panels"
import { Page } from "~/common/ui/Page"
import { ProvisionAddBillingRoot } from "~/widgets/ProvisionAddBillingRoot"

type Props = {
  context: Context
}

export const PageProvisionAddBillingRoot = (props: Props) => (
  <Page context={props.context} title="Provision">
    <NarrowContainerWithPadding>
      <h2 className="text-lg pb-4">
        Add New Billing Root to Stax Installation
      </h2>
      <p className="pb-3 text-gray-500">
        Available billing roots, are billing roots that have been created ahead
        of time prior to customer creation. This is necessary to negate delaying
        customer onboarding due to the general issues encountered when first
        creating an AWS billing root.
      </p>
      <ProvisionAddBillingRoot />
    </NarrowContainerWithPadding>
  </Page>
)
