import * as RemoteData from "~/models/RemoteData"
import useSWR from "swr"
import type { ApiJsonResponse } from "~/common/api/apiFetchers"
import type { DocumentType } from "@aws-amplify/core/internals/utils"
import { pipe, type Either } from "effect"
import { queryToRemoteData } from "~/common/utils/http"

type Parse<Data, Err> = (data: DocumentType) => Either.Either<Data, Err>

type Props<Data, Err> = {
  url: string
  shouldFetch?: boolean
  parse: Parse<Data, Err>
  onData?: (data: RemoteData.RemoteData<Data, Err>) => void
}

export const useQuery = <Data, Err>(props: Props<Data, Err>) => {
  const { url, parse, shouldFetch = true, onData } = props

  const onErrorUsingRemoteData = (error: Err) => {
    const remoteData: RemoteData.RemoteData<Data, Err> = RemoteData.Failure({
      error,
    })

    onData?.(remoteData)
  }

  const onSuccessUsingRemoteData = (data: ApiJsonResponse) => {
    const remoteData: RemoteData.RemoteData<Data, Err> = pipe(
      RemoteData.Success({
        data: data.json,
      }),
      RemoteData.map(parse),
      RemoteData.unwrapEither<Data, Err>,
    )

    onData?.(remoteData)
  }

  const query = useSWR<ApiJsonResponse, Err>(shouldFetch ? url : null, {
    onError: onErrorUsingRemoteData,
    onSuccess: onSuccessUsingRemoteData,
  })

  const remoteData = pipe(
    query,
    queryToRemoteData<Err>,
    RemoteData.map(parse),
    RemoteData.unwrapEither,
  )

  return { query, remoteData }
}
