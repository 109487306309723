import { Can, Role } from "~/common/ui/Can"
import type { Component } from "~/models/Component"
import type { Context } from "~/models/Context"
import { InlineControlAndLabel } from "~/common/ui/Forms"
import { Switch } from "~/common/ui/Switch"

type Props = {
  component: Component
  context: Context
  disabled?: boolean
  isChecked: boolean
  isBusy: boolean
  onAutoDeployComponent: (component: Component, value: boolean) => void
}

export const ComponentDeploySwitch = (props: Props) => {
  const { isChecked, disabled = false, component } = props

  const handleChange = (checked: boolean) => {
    props.onAutoDeployComponent(component, checked)
  }

  return (
    <Can
      currentUser={props.context.currentUser}
      requiredRole={Role.deployer}
      yes={
        <InlineControlAndLabel label="Auto Deploy" name="auto-deploy">
          <Switch
            checked={isChecked}
            disabled={disabled}
            busy={props.isBusy}
            name="auto-deploy"
            onChange={handleChange}
          />
        </InlineControlAndLabel>
      }
      no={
        <InlineControlAndLabel label="Auto Deploy" name="auto-deploy">
          <Switch
            checked={isChecked}
            disabled={true}
            busy={props.isBusy}
            name="auto-deploy"
            onChange={() => null}
          />
        </InlineControlAndLabel>
      }
    />
  )
}
