import { Schema } from "@effect/schema"
import { Array as EArray, Option, Number as ENumber, pipe } from "effect"

const IdamConfigSchema = Schema.Struct({
  comment: Schema.String,
  createdTS: Schema.String,
  customerId: Schema.String,
  key: Schema.String,
  organisationId: Schema.String,
  value: Schema.String,
})

export type IdamConfig = typeof IdamConfigSchema.Type

export const IdamConfigsResponseSchema = Schema.Array(IdamConfigSchema)

const findConfig = (idamConfigs: ReadonlyArray<IdamConfig>, key: string) =>
  EArray.findFirst(idamConfigs, (config) => config.key === key)

export const getAdminAccessRolesTTLInHoursAccount = (
  idamConfigs: ReadonlyArray<IdamConfig>,
  accountId: string,
): Option.Option<number> => {
  return pipe(
    findConfig(idamConfigs, `AdminAccessRolesTTLInHours/${accountId}`),
    Option.andThen((config) => ENumber.parse(config.value)),
  )
}

export const getAdminAccessRolesTTLInHours = (
  idamConfigs: ReadonlyArray<IdamConfig>,
): Option.Option<number> => {
  return pipe(
    findConfig(idamConfigs, "AdminAccessRolesTTLInHours"),
    Option.andThen((config) => ENumber.parse(config.value)),
  )
}
